import { inject, Injectable, Injector } from '@angular/core';
import { CombinedIds } from '@features/digital-venue/services/seat-management.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SeatsBySection } from '../models';
import { Seat } from '../models/seat.model';
import { DvmService } from './dvm.service';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  isMobile: boolean;
  isTablet: boolean;
  isDesktopDevice: boolean;
  device: {
    mobile: boolean;
    tablet: boolean;
    desktop: boolean;
  };
  ssoRoute?: string;

  /**
   * Translate node ids, from mmc id to tdc id
   */
  public nodeTranslator: Map<string, string> = new Map();

  /**
   * Translate node ids, from tdc_id to mmc_id
   */
  public tdcToMmcTranslator: Map<string, string> = new Map();

  private deviceService = inject(DeviceDetectorService);
  private injector = inject(Injector);

  constructor() {
    this.deviceDetector();
  }

  deviceDetector() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();

    this.device = {
      mobile: this.isMobile,
      tablet: this.isTablet,
      desktop: this.isDesktopDevice,
    };
  }

  distributeSeatsBySection(seats: Seat[]) {
    let seatsBySection: { [key: string]: SeatsBySection } = {};

    for (const seat of seats) {
      if (!seatsBySection[seat.section || seat.original_id]) {
        seatsBySection[seat.section || seat.original_id] = {
          id: seat.id.split('-')[0],
          original_id: seat.original_id,
          seats: {},
          totalFee: 0,
          totalSectionPrice: 0,
          totalSectionPriceWithFee: 0,
          totalSectionSeats: 0,
        };
      }
      const sectionKey = seat.section || seat.original_id;
      if (sectionKey) {
        (seatsBySection[sectionKey].seats as { [key: string]: Seat })[seat.id] =
          seat;
      }
    }

    return seatsBySection;
  }

  sortSectionsBySeatsQuantity(
    seatsBySection: SeatsBySection | Record<string, SeatsBySection>
  ) {
    let seatsBySectionArray: SeatsBySection[] = Object.values(seatsBySection);

    this.sectionsTotalSeatsCount(seatsBySectionArray);
    this.sectionsTotalPriceCount(seatsBySectionArray);

    if (seatsBySectionArray.length > 1) {
      // Sorting Sections if there are more than one with selected Seats
      let sortedSeatsBySectionArray = seatsBySectionArray.sort((a, b) => {
        return b.totalSectionSeats - a.totalSectionSeats;
      });
      return sortedSeatsBySectionArray;
    } else {
      return seatsBySectionArray;
    }
  }

  addRowDistribution(seatsBySectionArray: SeatsBySection[]): SeatsBySection[] {
    for (const section of seatsBySectionArray) {
      let sectionSeats: Seat[] = Object.values(section.seats);
      let seatsByRow: {
        [key: string]: {
          id: string;
          seats: {
            [key: string]: Seat;
          };
          seatsList: string[];
        };
      } = {};

      for (const seat of sectionSeats) {
        if (!seatsByRow[seat.seat_row as string]) {
          seatsByRow[seat.seat_row as string] = {
            id: seat.seat_row as string,
            seats: {},
            seatsList: [],
          };
        }
        seatsByRow[seat.seat_row as string].seats[seat.id] = seat;
        seatsByRow[seat.seat_row as string].seatsList.push(seat.seat as string);
      }

      section.rows = seatsByRow as any;
    }

    return seatsBySectionArray;
  }

  addRangeSeats(seatsBySectionAndRowArray: SeatsBySection[]) {
    seatsBySectionAndRowArray.forEach(section => {
      if (!section.rows) {
        return;
      }
      section.rows.forEach(row => {
        let ranges: Array<string | number> = [];
        let firstNum: number | undefined;
        let lastCurr: number | undefined;
        const seatList = row.seatsList.map(seat => seat.split('-')[2]);

        for (const seatNum of seatList) {
          const currNum = parseInt(seatNum, 10);
          // Si no existe firstNum
          if (!firstNum) {
            firstNum = currNum;
            lastCurr = currNum;
            // Si firstNum es el último num (sólo hay un seat seleccionado en la row)
            if (firstNum.toString() == seatList[seatList.length - 1]) {
              ranges.push(firstNum);
            }
            // Si ya hay firstNum
          } else {
            // Si hay num anterior y son consecutivos
            if (lastCurr && lastCurr + 1 === currNum) {
              lastCurr = currNum;
              // Si es el último num
              if (currNum.toString() == seatList[seatList.length - 1]) {
                // Si el primer y el último num tienen una diferencia de 2 o más
                if (currNum - firstNum >= 2) {
                  ranges.push(firstNum + '-' + currNum);
                  // Si no
                } else {
                  ranges.push(firstNum);
                  ranges.push(currNum);
                }
              }
              // Si hay num anterior y NO son consecutivos
            } else if (lastCurr && lastCurr + 1 !== currNum) {
              // Si el primer y el último num tienen una diferencia de 2 o más
              if (lastCurr - firstNum >= 2) {
                ranges.push(firstNum + '-' + lastCurr);
                // Si no
              } else {
                // Si el primer num no era el último checkeado, es decir, si había dos nums consecutivos pero no suficientes para hacer un rango, se tienen que guardar los dos
                if (firstNum !== lastCurr) {
                  ranges.push(firstNum);
                }
                ranges.push(lastCurr);
              }
              // Si es el último num
              if (currNum.toString() == seatList[seatList.length - 1]) {
                ranges.push(currNum);
              }
              firstNum = currNum;
              lastCurr = currNum;
            }
          }
        }
        // Store seatRanges
        row['seatsRanges'] = ranges.map(String);
      });
    });

    return seatsBySectionAndRowArray;
  }

  sectionsTotalSeatsCount(seatsBySectionArray: SeatsBySection[]) {
    for (const section of seatsBySectionArray) {
      section.totalSectionSeats = Object.keys(section.seats).length;
    }
  }

  sectionsTotalPriceCount(seatsBySectionArray: SeatsBySection[]) {
    for (const section of seatsBySectionArray) {
      section.totalSectionPrice = Object.values(section.seats).reduce(
        (total, seat) => total + seat['price'],
        0
      );
      section.totalSectionPriceWithFee = Object.values(section.seats).reduce(
        (total, seat) => total + seat['totalPriceWithFees'],
        0
      );

      section.totalFee = Object.values(section.seats).reduce(
        (total, seat) => total + seat['totalFee'],
        0
      );
    }
  }

  splittedSeatInfoFromId(seatIds: CombinedIds | string) {
    // "S_310-8-9"
    let seatInfo: {
      id: string;
      section: string;
      seat_row: string;
      seat: string;
      original_id?: string;
    };
    const isString = typeof seatIds === 'string';

    const splitedInfo = isString
      ? seatIds.split('-')
      : seatIds.original_id.split('-');

    // On general admission, the id received is the TDC one, so we get the MMC one from the service
    let mmcSeatId: string = '';
    if (isString) {
      const dvmService = this.injector.get(DvmService);
      mmcSeatId =
        (dvmService.translateSectionTdcId(seatIds) as string) ??
        (dvmService.translateSectionTdcId(splitedInfo[0]) as string);
    }

    seatInfo = {
      id: isString ? seatIds : seatIds.id,
      section: splitedInfo[0],
      seat_row: splitedInfo[1],
      seat: isString ? seatIds : splitedInfo[2],
      original_id: isString ? mmcSeatId : seatIds?.original_id,
    };

    return seatInfo;
  }

  totalSumApiSeatsPrice(seats: Seat[]): number {
    let sumPrice = seats.reduce(
      (accumulator, current) => accumulator + current.price,
      0
    );
    return sumPrice;
  }

  totalSumDvmSeatsPrices(seats: { [key: string]: Seat }) {
    let totalFee = 0;
    let totalPrice = 0;
    let totalPriceWithFees = 0;

    Object.values(seats).forEach(seat => {
      totalFee += seat.totalFee;
      totalPrice += seat.price;
      totalPriceWithFees += seat.totalPriceWithFees;
    });

    return { totalFee, totalPrice, totalPriceWithFees };
  }

  formatRgb(rgbString: string, wrapped = true) {
    return wrapped
      ? 'rgb(' + rgbString.split(' ').join(', ') + ')'
      : rgbString.split(' ').join(', ');
  }
}
