import { MapViewer } from '@3ddv/dvm-internal';
import { Component, inject } from '@angular/core';
import { SeatWithDefinitions } from '@core/models';
import { DvmService } from '@features/digital-venue/services/dvm.service';
import { ConnectionService } from '@features/pages/select-seat/services/connection.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-restricted-seats-modal',
  templateUrl: './restricted-seats-modal.component.html',
  styleUrl: './restricted-seats-modal.component.scss',
})
export class RestrictedSeatsModalComponent {
  bsModalRef = inject(BsModalRef);
  dvmService = inject(DvmService);
  connectionService = inject(ConnectionService);

  mapViewer: MapViewer = this.dvmService.viewer;
  title = 'Warning !';
  acceptFunc: () => void;
  seats: SeatWithDefinitions[];

  close() {
    this.bsModalRef.hide();
  }

  continue() {
    if (this.acceptFunc) {
      this.acceptFunc();
    }
    this.bsModalRef.hide();
  }
}
