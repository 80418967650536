export const environment = {
  production: false,
  mockTestClient: 'redsox',
  // REMINDER: when local dev, use the localhost endpoint
  apiUrl: 'https://dev-groupsales.service.3ddigitalvenue.com/api',
  // apiUrl: 'http://localhost:3333/api',
  oktaData: {
    clientId: '0oa1jbndylhbtMYt30h8',
    issuer: 'https://qa-ids.mlb.com/oauth2/ausg0y0s07iKGqVKv0h7',
  },
  analytics: {
    club: [],
    mmc: '',
  },
};
