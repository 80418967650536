import { MapViewer } from '@3ddv/dvm-internal';
import { inject, Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, throwError } from 'rxjs';
import { DvmService } from '@features/digital-venue/services/dvm.service';
import { ConnectionService } from '@features/pages/select-seat/services/connection.service';
import { ModalsService } from '../modals/modals.service';
import {
  GeneralAvailabilityDict,
  Seat,
  SeatAvailabilityDict,
  SeatAvailabilitySectionDict,
} from '../models';
import { PriceRange } from '../models/seat-availability.model';
import { ConfigurationService } from './configuration.service';
import { TutorialService } from './tutorial.service';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityService {
  viewer: MapViewer;
  sectionsAvailability: GeneralAvailabilityDict = {}; // Coming from load handler.
  seatsAvailability: SeatAvailabilitySectionDict = {}; // Coming from load handler.
  sectionsOnlyAdas: GeneralAvailabilityDict = {};
  minSeatsLimit: number;
  maxSeatsLimit: number;
  adaSeats: Seat[] = [];
  priceRange$ = new ReplaySubject<PriceRange>(1);
  priceRange: PriceRange = { min: 100000000, max: -1 }; // Están seteados así para que cambien al compararlos con los valores de los tickets.
  public test: { min: number; max: number } = { min: 100000000, max: -1 };

  private dvmService = inject(DvmService);
  private connectionService = inject(ConnectionService);
  private configurationService = inject(ConfigurationService);
  private modalsService = inject(ModalsService);
  private tutorialService = inject(TutorialService);

  constructor() {
    const viewerSubscription = this.dvmService.viewerSubject.subscribe({
      next: response => (this.viewer = response),
      error: error => {
        console.error(error);
        const modalData = {
          title: 'ERROR',
          content: 'An Error occurred while trying to get the Viewer.',
          acceptBtnName: 'CLOSE',
        };
        this.modalsService.openModal(modalData);
      },
    });
    this.dvmService.viewerSubscriptions.push(viewerSubscription);
  }

  getSectionAvailability(eventId = this.connectionService.eventId) {
    if (this.tutorialService.isOpen()) {
      return new Observable<Array<string>>(observer => {
        this.sectionsAvailability =
          this.tutorialService.getTutorialSectionAvailability();

        observer.next(this.tutorialService.getTutorialSectionAvailabilityIds());
        observer.complete();
      });
    }

    if (!this.connectionService.eventId) {
      return throwError(() => ({
        error: {
          message: `The event requested is invalid. If you are being redirected from an external website, please call ${this.configurationService.configuration.phone} or email ${this.configurationService.configuration.email}.`,
          code: 'E_INVALID_EVENT_ID',
        },
      }));
    }

    return this.connectionService.getGeneralAvailability(eventId).pipe(
      map(({ data }) => {
        const result: string[] = [];
        const availability = data as GeneralAvailabilityDict;

        Object.entries(availability).forEach(([mmcId, item]) => {
          if (item.availableQuantity + item.availableQuantityAda === 0) {
            delete availability[mmcId];
          } else {
            if (item.availableQuantity === 0 && item.availableQuantityAda > 0) {
              this.sectionsOnlyAdas[mmcId] = item;
              delete availability[mmcId];
              return;
            }
            if (item.priceRange[0].value < this.priceRange.min) {
              this.priceRange.min = item.priceRange[0].value;
            }
            if (item.priceRange[1].value > this.priceRange.max) {
              this.priceRange.max = item.priceRange[1].value;
            }
            this.priceRange$.next(this.priceRange);
            result.push(mmcId);
          }
        });

        this.sectionsAvailability = availability;

        return result;
      })
    );
  }

  getSeatAvailability(
    sectionsArray: string[],
    eventId = this.connectionService.eventId
  ) {
    if (this.tutorialService.isOpen()) {
      return new Observable<Array<string>>(observer => {
        this.seatsAvailability =
          this.tutorialService.getTutorialSeatsAvailability();

        observer.next(this.tutorialService.getTutorialSeatsAvailabilityIds());
        observer.complete();
      });
    }

    return this.connectionService
      .getMultiSectionAvailability(eventId, sectionsArray)
      .pipe(
        map(({ data }) => {
          const availability = data as SeatAvailabilityDict;

          sectionsArray.forEach(sectionId => {
            // Filter availability for this specific section
            const filteredAvailability = Object.entries(
              availability as SeatAvailabilityDict
            ).filter(([seatId, _]) => seatId.split('-')[0] === sectionId);
            this.seatsAvailability[sectionId] = Object.fromEntries(
              filteredAvailability
            ) as SeatAvailabilityDict;
          });

          return Object.keys(availability as SeatAvailabilityDict);
        })
      );
  }

  activeAdaSections() {
    this.dvmService.viewer.setAvailable(
      'section',
      Object.keys(this.sectionsOnlyAdas)
    );
  }

  deactivateOnlyAdaSections() {
    this.dvmService.viewer.setUnavailable(
      'section',
      Object.keys(this.sectionsOnlyAdas)
    );
  }

  getSeatByIdFromAvailability(seatId: string) {
    return this.seatsAvailability[seatId.split('-')[0]][seatId];
  }
}
