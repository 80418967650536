import { inject, Injectable } from '@angular/core';
import { ForbiddenEventModalComponent } from '@core/modals/forbidden-event-modal/forbidden-event-modal.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalData, SeatAvailability, SeatWithDefinitions } from '../models';
import { GaModalComponent } from './ga-modal/ga-modal.component';
import { ModalsComponent } from './modals.component';
import { RestrictedSeatsModalComponent } from './restricted-seats-modal/restricted-seats-modal.component';
import { SeatDefinitionsModalComponent } from './seat-definitions-modal/seat-definitions-modal.component';
import { TutorialWelcomeModalComponent } from './tutorial-welcome-modal/tutorial-welcome-modal.component';

export interface RestrictedSeatsModalPayload {
  func: () => void;
  seats: SeatAvailability[] | SeatWithDefinitions[];
}

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  bsModalRef?: BsModalRef;
  private modalService = inject(BsModalService);

  constructor() {}

  openModal({ ...modalData }: ModalData) {
    const initialState: ModalOptions = {
      initialState: modalData,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-lg',
    };
    this.bsModalRef = this.modalService.show(ModalsComponent, initialState);
  }

  openGaModal({ ...modalData }: ModalData) {
    const initialState: ModalOptions = {
      initialState: modalData,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-lg',
    };
    this.bsModalRef = this.modalService.show(GaModalComponent, initialState);
  }

  openSeatDefinitionModal(
    { seatDefinition, isAda }: SeatAvailability,
    seatId: string
  ) {
    const modalData = {
      definitions: seatDefinition,
      isAda,
      seatId,
    };
    const initialState: ModalOptions = {
      initialState: modalData,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-sm',
    };
    this.bsModalRef = this.modalService.show(
      SeatDefinitionsModalComponent,
      initialState
    );
  }

  openTutorialWelcomeModal({ ...modalData }) {
    const initialState: ModalOptions = {
      initialState: modalData,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-lg',
    };
    this.bsModalRef = this.modalService.show(
      TutorialWelcomeModalComponent,
      initialState
    );
  }

  openRestrictedSeatsModal({ func, seats }: RestrictedSeatsModalPayload) {
    const modalData = {
      acceptFunc: func,
      seats,
    };
    const initialState: ModalOptions = {
      initialState: modalData,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-lg',
    };
    this.bsModalRef = this.modalService.show(
      RestrictedSeatsModalComponent,
      initialState
    );
  }

  openForbiddenEventModal() {
    const initialState: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-lg',
    };
    this.bsModalRef = this.modalService.show(
      ForbiddenEventModalComponent,
      initialState
    );
  }

  closeModal() {
    if (!this.bsModalRef) {
      return;
    }
    this.modalService.hide(this.bsModalRef.id);
  }
}
