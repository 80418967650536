import { TutorialStep } from '@core/models';

export const seatSelectionDesktopTutorialSteps: TutorialStep[] = [
  {
    element: '#filters-header',
    command: 'activateMobileFiltersTab',
    popover: {
      description:
        '1. Start by selecting the total number of seats to purchase.',
    },
  },
  {
    element: '#filters-slider',
    command: 'activateMobileFiltersTab',
    popover: {
      description:
        '2. Use the slider to set your desired price range per ticket.',
    },
  },
  {
    element: '#filters-adas',
    command: 'activateMobileFiltersTab',
    popover: {
      description:
        '3. If necessary, select the number of wheelchair accessible seats you need. *Note that this number will be included in the total number of seats selected in step 1.',
      onNextClickMethod: 'loadTopViewMapOnNextClick',
    },
  },
  {
    element: '#map-container',
    popover: {
      description:
        '4. Use the seat map to select the section(s) you want to sit in. Selected sections appear in purple.',
      side: 'left',
      align: 'center',
      onNextClickMethod: 'showSectionPopoverOnNextClick',
    },
  },
  {
    element: '#tooltip',
    command: 'activateMobileCartTab',
    popover: {
      description:
        '5. Hover over a section to preview a view from that section. Click “Open Seat Map” to manually select seats in that section.',
      align: 'center',
      onPrevClickMethod: 'hidePopoverOnPrevClick',
      onNextClickMethod: 'loadSeatMapOnNextClick',
    },
  },
  {
    element: '#map-container',
    command: 'setSeatsInCart',
    popover: {
      description:
        '6. Selected seats appear red with a checkmark. If needed, change your selection by clicking each seat individually or hold Ctrl + click and drag to select a group of seats.',
      side: 'left',
      align: 'center',
      onPrevClickMethod: 'showSectionPopoverAfterTopviewMapLoadOnPrevClick',
      onNextClickMethod: 'loadTopViewMapOnNextClick',
    },
  },
  {
    element: '#cart-list',
    popover: {
      description:
        '7. Selected seats appear in the Cart. Click the map icon to go to that section in the map. Click the trash can to remove seats and make a different selection.',
      onPrevClickMethod: 'loadSeatMapOnPrevClick',
    },
  },
  {
    element: '#summary',
    popover: {
      description: '8. When you are ready to purchase, click “Go to Checkout.”',
    },
  },
];

export const seatSelectionMobileTutorialSteps: TutorialStep[] = [
  ...seatSelectionDesktopTutorialSteps.slice(0, 4),
  {
    ...seatSelectionDesktopTutorialSteps[4],
    popover: {
      ...seatSelectionDesktopTutorialSteps[4].popover,
      description:
        '5. Click a section to preview a view from that section. Click “Select“ to find your seats in that particular section or click “Open Seat Map“ to manually select seats in that section.',
    },
  },
  {
    ...seatSelectionDesktopTutorialSteps[5],
    popover: {
      ...seatSelectionDesktopTutorialSteps[5].popover,
      description:
        '6. Selected seats appear red with a checkmark. If needed, change your selection by clicking each seat.',
    },
  },
  ...seatSelectionDesktopTutorialSteps.slice(6),
];
