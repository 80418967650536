import { Component, inject } from '@angular/core';
import { ConfigurationService } from '@core/services';
import { DvmService } from '@features/digital-venue/services/dvm.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-tutorial-welcome-modal',
  templateUrl: './tutorial-welcome-modal.component.html',
  styleUrls: ['./tutorial-welcome-modal.component.scss'],
})
export class TutorialWelcomeModalComponent {
  title: string;
  content: string;
  closeBtnName: string;
  acceptBtnName: string;
  closeFunction: () => void;
  acceptFunction: () => void;

  // Legend
  availableColor: string = 'main-light';
  mySeatsColor: string = 'red';
  selectedColor: string = 'purple';
  isolatedColor: string = 'orange';

  get isTopview() {
    return this.dvmService.isTopview;
  }

  bsModalRef = inject(BsModalRef);
  configurationService = inject(ConfigurationService);
  private dvmService = inject(DvmService);

  constructor() {}

  accept() {
    this.bsModalRef.hide();
    if (this.acceptFunction) {
      this.acceptFunction();
    }
  }

  close() {
    this.bsModalRef.hide();
    if (this.closeFunction) {
      this.closeFunction();
    }
  }
}
