import {
  Configuration,
  Custom,
  DvmData,
  DvmStyles,
  Theme,
  Tutorial,
} from '@core/models';
import { seatSelectionDesktopTutorialSteps } from '@shared/tutorial/seat-selection/steps';

const custom: Custom = {
  paymentPage: {
    captureAddress: true,
    capturePhone: true,
  },
};

const theme: Theme = {
  'default-color': '00 00 00',
  'main-color': '00 00 00',
  'secondary-color': '187 13 48',
  'accent-color': '187 13 48',
  'tertiary-color': '242 246 250',
  red: '189 48 57',
  success: '86 163 67',
  'success-off-white': '236 246 232',
};

const dvmData: DvmData = {
  venueId: 'nam-us-10185-nationals',
  mapId: 'main_venuemap',
  miniMapId: 'main_venuemap_minimap',
  miniMapEnabled: false,
  gaSectorsIdArray: [],
  gaAdaIdArray: [],
  sectionOffsets: {},
  limitedZoom: false,
};

const dvmStyles: DvmStyles = {
  'seat-available-none-fill': '25 149 255',
  'seat-available-none-stroke': '0 90 156',
  'seat-available-disabled-fill': '25 149 255',
  'seat-available-disabled-stroke': '0 90 156',
  'seat-unavailable-none': '214 214 214',
  'seat-available-pending': '230 145 56',
  'section-available-normal-fill': '25 149 255',
  'section-available-normal-stroke': '0 90 156',
  'section-available-normal-group1-fill': '255 77 77',
  'section-available-normal-group1-stroke': '255 77 77',
  'section-available-hover-fill': '0 90 156',
  'section-available-hover-stroke': '255 255 255',
  'section-selected-normal-fill': '122 64 185',
  'section-selected-normal-stroke': '122 64 185',
  'section-selected-hover-stroke': '255 255 255',
};

const seatSelectionDesktopTutorial: Tutorial = {
  sectionAvailabilityIds: [
    '1254',
    '1256',
    '1258',
    '1261',
    '1074',
    '1076',
    '1078',
    '1080',
    '1066',
  ],
  availableSeatsQuantity: 240,
  selectedSeatsQuantity: 30,
  allowClose: false,
  doneBtnText: 'End Tutorial',
  nextBtnText: 'Next',
  popoverClass: 'driverjs-theme',
  prevBtnText: 'Back',
  steps: seatSelectionDesktopTutorialSteps,
};

const seatSelectionMobileTutorial: Tutorial = {
  ...seatSelectionDesktopTutorial,
  steps: [
    ...seatSelectionDesktopTutorial.steps.slice(0, 4),
    {
      ...seatSelectionDesktopTutorial.steps[4],
      popover: {
        ...seatSelectionDesktopTutorial.steps[4].popover,
        description:
          '5. Click a section to preview a view from that section. Click “Select“ to find your seats in that particular section or click “Open Seat Map“ to manually select seats in that section.',
      },
    },
    {
      ...seatSelectionDesktopTutorial.steps[5],
      popover: {
        ...seatSelectionDesktopTutorial.steps[5].popover,
        description:
          '6. Selected seats appear red with a checkmark. If needed, change your selection by clicking each seat.',
      },
    },
    ...seatSelectionDesktopTutorial.steps.slice(6),
  ],
};

const checkoutTutorial: Tutorial = {
  ...seatSelectionDesktopTutorial,
  steps: [
    {
      element: '#ticket-details',
      popover: {
        description: '1. Review  your ticket information.',
      },
    },
    {
      element: '#group-outing',
      popover: {
        description:
          '2. Enhance your group outing with an optional upgraded ticket.',
      },
    },
    {
      element: '#cart-summary',
      popover: {
        description:
          '3. Review the details of your group ticket pricing. Click “Proceed to Payment” to complete your purchase.',
      },
    },
  ],
};

export const WashingtonNationalsConfig: Configuration = {
  id: 'washingtonnationals',
  clientName: 'Washington Nationals',
  appName: 'Washington Nationals',
  city: 'Washington D.C.',
  favicon: '/assets/img/washingtonnationals/favicon.png',
  logo: '/assets/img/washingtonnationals/favicon.png',
  phone: '202-675-NATS (6287)',
  email: 'Ticket.info@nationals.com',
  paymentMethod: 'braintree',
  rainout: false,
  upgrades: true,
  clientWebUrl: 'https://www.nationals.com/',
  clientWebText: 'https://www.nationals.com/',
  clientBallParkUrl:
    'https://www.mlb.com/nationals/apps/ballpark?partnerId=redirect-chc-ballparkapp',
  clientBallParkText: 'nationals.com/BallparkApp',
  mlbShopUrl:
    'https://www.mlbshopeurope.com/en/washington-nationals/t-14553163+z-871253-930065732',
  merchandiseWebUrl: '',
  rainoutInsuranceUrl: '',
  termsAndConditionsUrl:
    'https://www.mlb.com/nationals/tickets/terms-and-conditions?_gl=1*olgww1*_gcl_au*MjA0NDE3MzE5MS4xNzI5MDg3NDIw',
  footerExtraText:
    'A $10 processing fee will be added to all orders at checkout.',
  analytics: '',
  adas: true,
  custom,
  theme,
  dvmData,
  dvmStyles,
  tutorial: {
    seatSelection: {
      desktop: seatSelectionDesktopTutorial,
      mobile: seatSelectionMobileTutorial,
    },
    checkout: { desktop: checkoutTutorial, mobile: checkoutTutorial },
  },
  extraCheckbox: {
    active: false,
    required: true,
    text: '',
  },
  summaryPage: {
    image1: '/assets/img/washingtonnationals/favicon.png',
    image2: '',
  },
  get legalText() {
    return {
      links: {
        termsLink: {
          href: this.termsAndConditionsUrl,
          text: 'Terms and Conditions',
          target: '_blank',
        },
      },
      text: [
        `By clicking Purchase, you agree to the Nationals {termsLink} as well as policies applicable to use. Ticket brokers are not eligible for this offer.`,
        `In connection with your purchase, you will receive ticket-related commercial emails from nationals.com. You may unsubscribe at any time. If you purchase tickets, you may receive customer service messages via e-mail from the Washington Nationals, including optional surveys regarding your ballpark experience.`,
        `All sales are final. There are no refunds or exchanges.`,
        `The Nationals reserve the right to change any and all 2025 access, benefits, promotions, seating locations and
        events (including, without limitation, location, dates and times) without notice based upon: 1) federal, city,
        state and/or local government orders, mandates or restrictions; 2) the guidance or recommendations of a
        recognized health agency (i.e. Centers for Disease Control or the World Health Organization); 3) any other
        reason as determined by the Nationals and/or Major League Baseball in their sole discretions. The terms and
        conditions of your ticket and policies applicable to use are updated to address COVID-19, health & safety
        protocols, proof of full vaccination, and seating pods.`,
      ],
    };
  },
  defaultTooltipText:
    'A $10 processing fee will be added to all orders at checkout.',
};
