import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ApiResponse } from '@core/models';
import { environment } from '@environments/environment';
import { ApiResponseError } from '../models/api-response.model';

export class ApiException extends Error {
  public code: ApiResponseError['code'];
  public data: ApiResponseError['data'];

  constructor(error: ApiResponseError) {
    super();
    this.message = error.message;
    this.code = error.code;
    this.data = error.data;
  }
}

export class BaseConnectionService {
  apiURL: string = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': `application/json`,
    }),
  };

  protected handleApiResponse<T>(
    observable: Observable<ApiResponse<T>>
  ): Observable<ApiResponse<T>> {
    return observable.pipe(
      map(obj => {
        if (obj.error) {
          throw new ApiException(obj.error);
        }

        return obj;
      }),
      catchError(error => {
        console.error('API ERROR: ', error);
        return throwError(() => new ApiException(error.error.error));
      })
    );
  }

  protected sendRequest<T>(
    http: HttpClient,
    endpoint: string,
    body: any = null
  ) {
    const finalUrl = `${endpoint}`;
    const observable = body
      ? http.post<ApiResponse<T>>(finalUrl, body, this.httpOptions)
      : http.get<ApiResponse<T>>(finalUrl, this.httpOptions);
    return this.handleApiResponse(observable);
  }
}
