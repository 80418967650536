import { Configuration, Custom, Tutorial } from '@core/models';
import { checkoutTutorialSteps } from '../tutorial/checkout/steps';
import {
  seatSelectionDesktopTutorialSteps,
  seatSelectionMobileTutorialSteps,
} from '../tutorial/seat-selection/steps';

const custom: Custom = {
  paymentPage: {
    captureAddress: false,
    capturePhone: true,
  },
};

const seatSelectionDesktopTutorial: Tutorial = {
  sectionAvailabilityIds: ['S_108', 'S_107', 'S_117', 'S_102'],
  availableSeatsQuantity: 240,
  selectedSeatsQuantity: 30,
  allowClose: false,
  doneBtnText: 'End Tutorial',
  nextBtnText: 'Next',
  popoverClass: 'driverjs-theme',
  prevBtnText: 'Back',
  steps: seatSelectionDesktopTutorialSteps,
};

const seatSelectionMobileTutorial: Tutorial = {
  ...seatSelectionDesktopTutorial,
  steps: seatSelectionMobileTutorialSteps,
};

const checkoutTutorial: Tutorial = {
  ...seatSelectionDesktopTutorial,
  steps: checkoutTutorialSteps,
};

export const ChicagoCubsConfig: Configuration = {
  id: 'chicagocubs',
  clientName: 'Chicago Cubs',
  appName: 'Chicago Cubs',
  city: 'Chicago',
  favicon: '/assets/img/favicon.png',
  logo: '/assets/img/logo.svg',
  phone: '733-404-4242',
  rainout: true,
  upgrades: true,
  adas: true,
  email: 'cubsgrouptickets@cubs.com',
  paymentMethod: 'braintree',
  clientWebUrl: 'http://www.cubs.com/',
  clientWebText: 'www.cubs.com.',
  clientBallParkUrl:
    'https://www.mlb.com/cubs/apps/ballpark?partnerId=redirect-chc-ballparkapp',
  clientBallParkText: 'Cubs.com/BallparkApp',
  mlbShopUrl:
    'https://www.mlbshop.com/chicago-cubs/t-36228622+z-808474-1125435766',
  merchandiseWebUrl: '',
  rainoutInsuranceUrl:
    'https://www.mlb.com/cubs/tickets/group-tickets/rainout-insurance',
  termsAndConditionsUrl:
    'https://www.mlb.com/cubs/tickets/terms-and-conditions',
  analytics: '',
  theme: {
    'default-color': '0 90 156',
    'main-color': '0 90 156',
    'secondary-color': '25 149 255',
    'tertiary-color': '242 246 250',
    'accent-color': '189 48 57',
    red: '225 56 63',
    success: '86 163 67',
    'success-off-white': '236 246 232',
  },
  dvmData: {
    venueId: 'nam-us-00096-chicagocubs',
    mapId: 'main_venuemap',
    miniMapId: 'blockmap_split_minimap',
    miniMapEnabled: true,
    gaSectorsIdArray: ['15404'],
    gaAdaIdArray: ['15427'],
    sectionOffsets: {
      '15404': [-80, -360],
    },
    limitedZoom: false,
  },
  dvmStyles: {
    'seat-available-none-fill': '25 149 255',
    'seat-available-none-stroke': '0 90 156',
    'seat-available-disabled-fill': '25 149 255',
    'seat-available-disabled-stroke': '0 90 156',
    'seat-unavailable-none': '214 214 214',
    'seat-available-pending': '230 145 56',
    'section-available-normal-fill': '25 149 255',
    'section-available-normal-stroke': '0 90 156',
    'section-available-normal-group1-fill': '255 77 77',
    'section-available-normal-group1-stroke': '255 77 77',
    'section-available-hover-fill': '0 90 156',
    'section-available-hover-stroke': '255 255 255',
    'section-selected-normal-fill': '122 64 185',
    'section-selected-normal-stroke': '122 64 185',
    'section-selected-hover-stroke': '255 255 255',
  },
  custom,
  tutorial: {
    seatSelection: {
      desktop: seatSelectionDesktopTutorial,
      mobile: seatSelectionMobileTutorial,
    },
    checkout: { desktop: checkoutTutorial, mobile: checkoutTutorial },
  },
  summaryPage: {
    image1: '/assets/img/cubsspring/cubs_logo.png',
    image2: '',
  },
  get legalText() {
    return {
      links: {
        termsLink: {
          href: this.termsAndConditionsUrl,
          text: 'Terms and Conditions',
          target: '_blank',
        },
        clubWebLink: {
          href: this.clientWebUrl,
          text: this.clientWebText,
          target: '_blank',
        },
      },
      text: [
        `As part of your ticket purchase, you are required to acknowledge the {termsLink}.
        By clicking the Place Order button, you agree to accept all {termsLink} applicable to ticket purchase, and all related policies.
        Further, due to the COVID-19 pandemic, scheduling may be impacted and games may be canceled,
        rescheduled, postponed or games may be held with limited capacity. Games may also have restricted capacity that prevents the use of specific
        seats. In the event that the game(s) for which the ticket(s) is issued does not occur or you are unable to use the
        specific seat(s) purchased, a refund or credit will be issued according to the refund policy available at {clubWebLink}.`,

        `By selecting the Place Order button you agree to purchase the tickets selected, to accept any restrictions noted, and
        understand you are responsible for any payment needed to complete the purchase. Upon completing your purchase by
        selecting the Place Order button, you acknowledge and agree your credit card will be charged. All sales are final. All
        tickets are subject to the {termsLink}.`,

        `In connection with your purchase, you may receive customer service-related emails from the ${this.clientName}, including
        optional surveys regarding your gameday experience. You also may receive ticket-related commercial email from the
        ${this.clientName}. You may unsubscribe from ticket-related commercial emails at any time.`,
      ],
    };
  },
  defaultTooltipText: 'Review seats before continuing',
};
