import { TutorialStep } from '@core/models';

export const checkoutTutorialSteps: TutorialStep[] = [
  {
    element: '#ticket-details',
    popover: {
      description: '1. Review  your ticket information.',
    },
  },
  {
    element: '#group-outing',
    popover: {
      description:
        '2. Enhance your group outing with an optional upgraded ticket.',
    },
  },
  {
    element: '#cart-summary',
    popover: {
      description:
        '3. Review the details of your group ticket pricing. Click “Proceed to Payment” to complete your purchase.',
    },
  },
];
