import { MapViewer } from '@3ddv/dvm-internal';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { Seat } from '@core/models';
import {
  AvailabilityService,
  CartService,
  ConfigurationService,
  DvmService,
  TutorialService,
  UtilitiesService,
} from '@core/services';
import { Subscription } from 'rxjs';
import { IsolatedSeatsService } from './isolated-seats.service';

export type CombinedIds = {
  original_id: string;
  id: string;
};

@Injectable({
  providedIn: 'root',
})
export class SeatManagementService implements OnDestroy {
  private availabilityService: AvailabilityService =
    inject(AvailabilityService);
  private cartService: CartService = inject(CartService);
  private configurationService: ConfigurationService =
    inject(ConfigurationService);
  private dvmService: DvmService = inject(DvmService);
  private isolatedSeatsService: IsolatedSeatsService =
    inject(IsolatedSeatsService);
  private tutorialService: TutorialService = inject(TutorialService);
  private utilitiesService: UtilitiesService = inject(UtilitiesService);
  needADA: boolean = false;
  subscriptions: Subscription[] = [];
  viewer: MapViewer;

  constructor() {
    const tutorial$ = this.tutorialService.tutorial$.subscribe(
      tutorialConfig => {
        if (tutorialConfig.activeStepCommand === 'setSeatsInCart') {
          const selectedSeatNodes =
            this.tutorialService.getTutorialSelectedSeatNodes();

          this.selectAvailableSeats(
            selectedSeatNodes.map(node => ({
              id: node.id,
              original_id: node.original_id,
            }))
          );
        }

        if (tutorialConfig.isTutorialEnded) {
          this.unselectAllSelectedSeats();
        }
      }
    );
    this.dvmService.viewerSubscriptions.push(tutorial$);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  selectAvailableSeats(nodeIds: CombinedIds[]) {
    // Reset selectedSeats if you are adding a group of seats (best available or area selection)
    if (nodeIds.length > 1) {
      this.dvmService.viewerService.unselectAll();
      this.cartService.selectedSeats = {};
      this.cartService.selectedSeats$.next(this.cartService.selectedSeats);
    }

    let ids = nodeIds.map(node => node.id);
    // Select Seat(s) on Viewer
    this.dvmService.viewerService.select(ids);

    this.selectSeatsForCart(nodeIds);
  }

  unselectSelectedSeats(
    nodeIds: { id: string; original_id?: string; originalId?: string }[]
  ) {
    // Unselect Seat on Viewer
    let success = this.dvmService.viewerService.unselect(nodeIds);

    for (const nodeId of nodeIds) {
      // Remove one new seat (in Cart Service)
      let isDeleted = delete this.cartService.selectedSeats[nodeId.id];
      this.cartService.selectedSeats$.next(this.cartService.selectedSeats);
    }

    const { totalFee, totalPrice, totalPriceWithFees } =
      this.utilitiesService.totalSumDvmSeatsPrices(
        this.cartService.selectedSeats
      );
    this.cartService.selectedSeatsTotalFee = totalFee;
    this.cartService.selectedSeatsTotalPrice = totalPrice;
    this.cartService.selectedSeatsTotalPriceWithFees = totalPriceWithFees;

    // Update cart Seats data
    this.updateCartSeatsBySection();
  }

  unselectAllSelectedSeats() {
    let success = this.dvmService.viewerService.unselectAll();

    // Reset new seats (in Transaction Service)
    this.cartService.selectedSeats = {};
    this.cartService.selectedSeats$.next(this.cartService.selectedSeats);

    const { totalFee, totalPrice, totalPriceWithFees } =
      this.utilitiesService.totalSumDvmSeatsPrices(
        this.cartService.selectedSeats
      );
    this.cartService.selectedSeatsTotalFee = totalFee;
    this.cartService.selectedSeatsTotalPrice = totalPrice;
    this.cartService.selectedSeatsTotalPriceWithFees = totalPriceWithFees;

    // Update cart Seats data
    this.updateCartSeatsBySection();

    this.cartService.resetCart();

    // if we are in seat view return to Topview
    if (!this.dvmService.isTopView()) {
      const subscription = this.dvmService.openSectionMap().subscribe();
      this.subscriptions.push(subscription);
    }
  }

  unselectAllSelectedSeatsWithoutLoadingMap() {
    const selectedSeats = this.dvmService.viewerService.getNodes({
      type: ['seat'],
      state: 'selected',
    });
    this.dvmService.viewerService.unselect(selectedSeats);
    // Reset new seats (in Transaction Service)
    this.cartService.selectedSeats = {};
    this.cartService.selectedSeats$.next(this.cartService.selectedSeats);

    const { totalFee, totalPrice, totalPriceWithFees } =
      this.utilitiesService.totalSumDvmSeatsPrices(
        this.cartService.selectedSeats
      );
    this.cartService.selectedSeatsTotalFee = totalFee;
    this.cartService.selectedSeatsTotalPrice = totalPrice;
    this.cartService.selectedSeatsTotalPriceWithFees = totalPriceWithFees;

    // Update cart Seats data
    this.updateCartSeatsBySection();
  }

  selectSeatsForCart(nodeIds: CombinedIds[] | string[]) {
    // Add seats to Cart
    for (const node of nodeIds) {
      // Merging new seat info from two objects:
      let newSeat = this.utilitiesService.splittedSeatInfoFromId(node) as Seat;
      let id: string;
      if (typeof node === 'string') {
        id = node;
      } else {
        id = node.id;
      }

      const seatInfo = this.availabilityService.getSeatByIdFromAvailability(id);
      newSeat.price = seatInfo?.price?.[0]?.value; //The first price is the default buyer type price.
      newSeat.totalFee = seatInfo?.price?.[0]?.totalFee; //The first price is the default buyer type price.
      newSeat.totalPriceWithFees = seatInfo?.price?.[0]?.totalPriceWithFees; //The first price is the default buyer type price.
      // Add one new seat (in Cart Service)

      this.cartService.selectedSeats[newSeat.id] = newSeat;
      this.cartService.selectedSeats$.next(this.cartService.selectedSeats);
    }

    // Update cart Seats data
    this.updateCartSeatsBySection();

    const { totalFee, totalPrice, totalPriceWithFees } =
      this.utilitiesService.totalSumDvmSeatsPrices(
        this.cartService.selectedSeats
      );
    this.cartService.selectedSeatsTotalFee = totalFee;
    this.cartService.selectedSeatsTotalPrice = totalPrice;
    this.cartService.selectedSeatsTotalPriceWithFees = totalPriceWithFees;
  }

  updateCartSeatsBySection() {
    this.cartService.selectedSeatsBySection =
      this.utilitiesService.distributeSeatsBySection(
        Object.values(this.cartService.selectedSeats)
      );

    this.cartService.sortedSelectedSeatsBySectionArray =
      this.utilitiesService.sortSectionsBySeatsQuantity(
        this.cartService.selectedSeatsBySection
      );
    this.cartService.sortedSelectedSeatsBySectionArray$.next(
      this.cartService.sortedSelectedSeatsBySectionArray
    );

    this.cartService.selectedSeats$.next(this.cartService.selectedSeats);
    this.isolatedSeatsService.isolatedSeatsActive = false;

    this.isolatedSeatsService.lastIsolatedSeats = null;
    this.dvmService.viewerService.removeNodesFromGroup(
      this.dvmService.viewerService.getNodesByGroups('seat', 'pending'),
      'pending'
    );
  }

  updateSelectedSectionsOnTopview() {
    // Get selected sections from cartService
    const selectedSeatsSectionIds = Object.values(
      this.cartService.selectedSeatsBySection
    );
    const nodeList = selectedSeatsSectionIds.map(item => item.id);
    // Update selected sections on Topview
    let nodes = this.dvmService.viewerService.getNodesByGroups(
      'section',
      'group1'
    );
    this.dvmService.viewerService.removeNodesFromGroup(nodes, 'group1');
    this.dvmService.viewerService.addNodesToGroup(nodeList, 'group1');

    nodeList.forEach(node => {
      this.dvmService.viewerService.setAvailable('section', node);
    });

    const dvmData = this.configurationService.configuration.dvmData;
    // Update selected sections on Mini Map

    if (dvmData.miniMapEnabled && dvmData.miniMapId) {
      this.dvmService.minimapService.unselectAll();
      let success = this.dvmService.minimapService.select(
        selectedSeatsSectionIds
      );
      let selectedMini = this.dvmService.minimapService.getNodesByState(
        'section',
        'selected'
      );
    }
  }
}
