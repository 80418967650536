import { MapViewerOutputNode } from '@3ddv/dvm-internal';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { SeatAvailability } from '@core/models';
import { DvmService, UtilitiesService } from '@core/services';
import { Subscription } from 'rxjs';
import { Popover3dviewService } from '../../services/popover-3dview.service';

@Component({
  selector: 'app-seat-popover',
  templateUrl: './seat-popover.component.html',
  styleUrls: ['./seat-popover.component.scss'],
})
export class SeatPopoverComponent implements OnInit, OnDestroy {
  private dvmService = inject(DvmService);
  private popover3dview = inject(Popover3dviewService);
  private utilitiesService = inject(UtilitiesService);
  seatData: SeatAvailability;
  subscriptions: Subscription[] = [];
  tooltipData: { section: string; row: string; seat: string; img: string };

  constructor() {}

  ngOnInit(): void {
    const seatNode = this.dvmService.viewerService.getNodeById(
      this.seatData.id
    );

    this.initializeComponent(seatNode);
  }

  initializeComponent(seatNode: MapViewerOutputNode) {
    if (!seatNode) {
      return;
    }

    const seatInfo = this.utilitiesService.splittedSeatInfoFromId(
      seatNode.original_id
    );

    this.tooltipData = {
      section: seatInfo.section,
      row: seatInfo.seat_row,
      seat: seatInfo.seat,
      img: '',
    };

    const subscription = this.dvmService
      .loadThumbnail(this.seatData.id)
      .subscribe(img => {
        this.tooltipData.img = img.src;
      });
    this.subscriptions.push(subscription);
  }

  onEnter() {
    this.popover3dview.deleteTimer();
  }

  onLeave() {
    this.popover3dview.setTimer();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
