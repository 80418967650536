import {
  MapViewer,
  MapViewerBestNodesAlgorithm,
  MapViewerGetBestNodesInput,
  MapViewerRowMember,
} from '@3ddv/dvm-internal';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { ModalsService } from '@core/modals/modals.service';
import { Seat } from '@core/models';
import {
  ApiException,
  AvailabilityService,
  CartService,
  ConfigurationService,
  DvmService,
} from '@core/services';
import { Subscription } from 'rxjs';
import { CombinedIds, SeatManagementService } from './seat-management.service';

@Injectable({
  providedIn: 'root',
})
export class BestAvailableService implements OnDestroy {
  private dvmService: DvmService = inject(DvmService);
  private seatManagementService: SeatManagementService = inject(
    SeatManagementService
  );
  private configurationService: ConfigurationService =
    inject(ConfigurationService);
  private availabilityService: AvailabilityService =
    inject(AvailabilityService);
  private cartService: CartService = inject(CartService);
  private modalsService: ModalsService = inject(ModalsService);
  viewer: MapViewer;
  selectedSectionsForBA: string[] = [];
  bestSeats: Seat[];
  bestSeatsIds: CombinedIds[];
  lastSelectedSection: string;
  subscriptions: Subscription[] = [];

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public selectSectionForBA(nodeId: string) {
    // Select Section on Viewer
    this.dvmService.viewerService.select([nodeId]);

    this.selectedSectionsForBA = this.dvmService.viewerService
      .getNodesByState('section', 'selected')
      .map(node => node.id);
  }

  public unselectSectionForBA(nodeId: string) {
    // Select Section on Viewer
    this.dvmService.viewerService.unselect([nodeId]);
    this.selectedSectionsForBA = this.dvmService.viewerService
      .getNodesByState('section', 'selected')
      .map(node => node.id);
  }

  public getBestXSeats(seatsQty: number, adaSeatsQty?: number) {
    const sectionsSelection = this.getBestSeatsSectionsSelection();

    if (sectionsSelection.length === 0 || sectionsSelection[0] == null) {
      this.openModalWithNoAvailableBA();
      this.seatManagementService.unselectAllSelectedSeats();
      return;
    }

    const subscription = this.availabilityService
      .getSeatAvailability(sectionsSelection)
      .subscribe({
        next: availability => {
          this.dvmService.viewerService.setAvailability('seat', availability);

          if (!seatsQty) {
            if (adaSeatsQty) {
              this.selectBestAdaSeats(adaSeatsQty, undefined);
            }

            this.setBestSeatsAvailability(availability);

            return;
          }

          const seatsAvailability = this.getBestSeatsAvailability(
            adaSeatsQty,
            availability
          );

          if (!this.dvmService.viewerService.best_nodes) return;

          const bestNodesInput: MapViewerGetBestNodesInput = {
            type: 'seat',
            quantity: seatsQty,
            algorithm: MapViewerBestNodesAlgorithm.NEIGHBORS,
            filter_single_nodes: true,
            subset: seatsAvailability,
          };

          const subscription = this.dvmService.viewerService.best_nodes
            .getBestNodes(bestNodesInput)
            .subscribe({
              next: nodes => {
                if (!Array.isArray(nodes) || nodes.length === 0) {
                  this.openModalWithNoAvailableBA();
                  this.seatManagementService.unselectAllSelectedSeats();
                  return;
                }

                this.bestSeatsIds = nodes.map(node => {
                  return { id: node.id, original_id: node.original_id };
                });
                this.seatManagementService.selectSeatsForCart(
                  this.bestSeatsIds
                );

                // ADA Selection flow
                if (adaSeatsQty && adaSeatsQty > 0) {
                  const selectedSections = [
                    ...new Set(
                      this.bestSeatsIds.map(item => item.id.split('-')[0])
                    ),
                  ];

                  this.selectBestAdaSeats(adaSeatsQty, selectedSections);
                }

                const hasMatchingBestSeats =
                  this.setBestSeatsAvailability(availability);

                if (!hasMatchingBestSeats) {
                  this.openModalWithNoAvailableBA();
                  this.seatManagementService.unselectAllSelectedSeats();
                }
              },
              error: error => {
                // FIXME: Bad 'type' input: seat is not an used type. at Object.getBestNodes
                console.error(error);
                this.openModalWithNoAvailableBA();
                this.seatManagementService.unselectAllSelectedSeats();
              },
            });

          this.subscriptions.push(subscription);
        },
        error: error => {
          console.error(error);
          const modalData = {
            title: 'ERROR',
            content: 'An Error occurred while trying to get Seat Availability.',
            acceptBtnName: 'CLOSE',
          };

          // If there's a custom api error.
          if (error instanceof ApiException) {
            modalData.content = error.message;
          }
          this.modalsService.openModal(modalData);
        },
      });

    this.subscriptions.push(subscription);
  }

  private getBestSeatsSectionsSelection() {
    let sectionsSelection: string[] = [];

    this.seatManagementService.unselectAllSelectedSeatsWithoutLoadingMap();

    if (this.selectedSectionsForBA.length) {
      sectionsSelection = this.selectedSectionsForBA;
      // todo GA change quitar los ga del best available
    } else {
      sectionsSelection = this.dvmService.viewerService
        .getNodesByState('section', 'available')
        .map(a => a.id);
    }

    const sectionsHolder = [];

    for (const section of sectionsSelection.values()) {
      if (
        !this.configurationService.configuration.dvmData.gaSectorsIdArray.includes(
          section
        ) &&
        !this.configurationService.configuration.dvmData.gaAdaIdArray.includes(
          section
        )
      ) {
        if (section != null) {
          sectionsHolder.push(section);
        }
      }
    }

    return sectionsHolder;
  }

  private getBestSeatsAvailability(
    adaSeatsQty: number | undefined,
    availability: string[]
  ): string[] {
    let avSeatsForBA: string[] = [];

    // if the user has current seats, they must be removed from availability
    let currentSeats = Object.keys(this.cartService.selectedSeats);
    // Remove ADA seats from the subset
    const adaSeats = this.dvmService.viewerService
      .getNodesByTag('seat', 'ada')
      .map(a => a.id)
      .concat(
        this.dvmService.viewerService
          .getNodesByTag('seat', 'adacomp')
          .map(a => a.id)
      );

    if (adaSeatsQty) {
      const result = {
        ...this.availabilityService.sectionsAvailability,
        ...this.availabilityService.sectionsOnlyAdas,
      };

      avSeatsForBA = availability.filter(a => {
        const sectionId = a.split('-')[0];
        const section = result[sectionId];
        return (
          section.availableQuantityAda > 0 && section.availableQuantity > 0
        );
      });

      if (
        avSeatsForBA.length === 0 ||
        avSeatsForBA.filter(x => !adaSeats.includes(x)).length === 0
      ) {
        avSeatsForBA = availability.filter(a => {
          const sectionId = a.split('-')[0];
          const section = result[sectionId];
          return section.availableQuantity > 0;
        });
      }
    } else {
      avSeatsForBA = availability;
    }

    return avSeatsForBA
      .filter(x => !adaSeats.includes(x))
      .filter(x => !currentSeats.includes(x));
  }

  private setBestSeatsAvailability(availability: string[]): boolean {
    const mostSelectedSeatsSectionId =
      this.cartService.sortedSelectedSeatsBySectionArray[0].id;

    if (mostSelectedSeatsSectionId) {
      const subscription = this.dvmService
        .openSeatMap(mostSelectedSeatsSectionId)
        .subscribe({
          next: () => {
            this.dvmService.viewerService.setAvailability('seat', availability);

            this.seatManagementService.selectAvailableSeats(this.bestSeatsIds);
          },
        });

      this.subscriptions.push(subscription);

      return true;
    }

    return false;
  }

  private selectBestAdaSeats(
    number: number,
    preferredSections?: string | string[]
  ): string[] {
    const accessibleSectionsIds: string[] = [];
    let seatsToSelect = number;

    Object.entries(this.availabilityService.seatsAvailability).forEach(
      ([sectionId, seatsAvailability]) => {
        if (seatsToSelect <= 0) return;

        if (preferredSections && !preferredSections.includes(sectionId)) return;

        const adaSeatsIds = Object.values(seatsAvailability)
          .filter(
            seat => seat.isAda && !this.cartService.selectedSeats[seat.id]
          )
          .map(seat => seat.id);

        if (adaSeatsIds.length) {
          const consecutiveGroups = this.getConsecutiveGroups(adaSeatsIds);

          // Si tenemos una selección impar y tenemos sillas en grupos impares, primero seleccionamos esto
          if (seatsToSelect % 2 && consecutiveGroups.odd.length) {
            this.seatManagementService.selectSeatsForCart([
              consecutiveGroups.odd[0],
            ]);

            seatsToSelect = seatsToSelect - 1;
          }

          // Si quedan sillas por seleccionar...
          if (seatsToSelect > 0) {
            // Seleccionamos el resto, normalmente grupos pares
            consecutiveGroups.even.forEach(adaSeatId => {
              if (seatsToSelect <= 0) return;

              const adaSeatNode = this.dvmService.viewerService.getNodeById(
                adaSeatId.toString()
              );

              this.seatManagementService.selectSeatsForCart([
                { id: adaSeatNode!.id, original_id: adaSeatNode!.original_id },
              ]);

              seatsToSelect = seatsToSelect - 1;
            });
          }

          accessibleSectionsIds.push(sectionId);
        } else {
          console.warn('This section has no ADA availability: ', sectionId);
        }
      }
    );

    if (seatsToSelect > 0 && preferredSections != null) {
      this.selectBestAdaSeats(seatsToSelect, undefined);
    } else if (seatsToSelect > 0) {
      this.openModalWithNoAvailableBA();
      this.seatManagementService.unselectAllSelectedSeatsWithoutLoadingMap();
    }

    return accessibleSectionsIds;
  }

  private getConsecutiveGroups(adaSeatsIds: string[]): {
    even: string[];
    odd: string[];
  } {
    // Utilizamos la Row info para calcular las sillas contiguas
    const rowSeatsIds = this.dvmService.viewerService.getNodeById(
      adaSeatsIds[0]
    )!['row'];

    // Filtramos de la Row info las sillas que sabemos que son ADA
    const adaRowSeatsIds = rowSeatsIds!.filter(seatId => {
      const x = this.dvmService.viewerService.getNodeById(seatId!);
      return x!['tag'] === 'ada' || x!['tag'] === 'adacomp';
    });

    // Ordenamos nuestra availability según el orden de la Row info
    adaSeatsIds.sort((a, b) => {
      const ia = adaRowSeatsIds.indexOf(a);
      const ib = adaRowSeatsIds.indexOf(b);
      if (ia < 0) return 1;
      if (ib < 0) return -1;
      return ia - ib;
    });

    // Buscamos los grupos consecutivos
    const consecutiveGroups: NonNullable<MapViewerRowMember>[][] = [];
    let lastGroup = -1;

    adaSeatsIds.forEach(seatId => {
      const seatIdRowIndex = adaRowSeatsIds.indexOf(seatId);
      const lastItem = adaRowSeatsIds[seatIdRowIndex - 1];
      const isNewConsecutiveGroupsItem = !(
        lastGroup > -1 &&
        consecutiveGroups[lastGroup] &&
        consecutiveGroups[lastGroup].includes(lastItem as string)
      );

      if (isNewConsecutiveGroupsItem) {
        lastGroup = lastGroup + 1;
        consecutiveGroups[lastGroup] = [];
      }

      consecutiveGroups[lastGroup].push(seatId);
    });

    // Dividimos los grupos consecutivos por pares e impares
    const result = {
      even: [] as string[],
      odd: [] as string[],
    };

    consecutiveGroups.forEach(group => {
      if (group.length % 2) {
        result.odd.push(...group);
      } else {
        result.even.push(...group);
      }
    });

    // Los grupos impares se pueden convertir en grupos pares restando una de las sillas
    // El resultado es una array de todas las sillas que dan grupos pares,
    // mas una silla separada en caso que haya un recuento impar
    // TODO: esto no lo entendi.
    // Por que hacen odd.indexOf luego de haber tomado el primer item (index = 0) del array odd???
    // Por que al hacer splice hacen .length - 2 en lugar de usar el index del item que tomaron en la linea anterior???
    if (result.odd.length > 0) {
      const oddGroupSeatId = result.odd[0];
      const oddGroupSeatIndex = result.odd.indexOf(oddGroupSeatId);

      // restar 2 sillas de odd y pasarlas a even
      const toAdd = result.odd.splice(
        oddGroupSeatIndex,
        oddGroupSeatId.length - 2
      );

      result.even.push(...toAdd);
    }

    return result;
  }

  private openModalWithNoAvailableBA() {
    console.warn('NO Best Nodes');
    const modalData = {
      title: 'We are sorry!',
      content:
        'We could not find results matching your Best Seats selection. Please, try again with a different selection.',
      acceptBtnName: 'OK',
    };
    this.modalsService.openModal(modalData);
  }
}
