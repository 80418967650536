<div class="w-full text-center rounded-2.5xl">
  <!-- Header -->
  <div class="border-b border-gray-300 px-12 py-6 md:px-24 md:py-12">
    <h1 class="text-xl md:text-3xl" [innerHTML]="title"></h1>
    <h2 class="text-sm md:text-base" [innerHTML]="subtitle"></h2>
  </div>
  <!-- <hr> -->
  <!-- Body -->
  <div class="px-12 py-6 md:px-24 md:py-12">
    <h4 class="font-normal">{{ content }}</h4>
    <label for="ga-seats" class="label">Quantity of seats: </label>
    <input
      type="number"
      min="0"
      [(ngModel)]="gaNumSeats"
      oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
      class="input w-32 ml-4 font-bold text-center inline-block"
      name="ga-seats"
      id="ga-seats" /><!-- pl-9 -->
    <div class="flex justify-center mt-6 md:mt-12">
      @if (closeBtnName) {
        <button
          (click)="close()"
          class="btn-main-reverse mr-3 md:mr-6 disabled:opacity-30 transition-transform hover:scale-95 disabled:hover:transform-none">
          {{ closeBtnName }}
        </button>
      }
      @if (acceptBtnName) {
        <button
          class="btn-main disabled:opacity-30 transition-transform hover:scale-95 disabled:hover:transform-none"
          (click)="accept()">
          {{ acceptBtnName }}
        </button>
      }
    </div>
  </div>
</div>
