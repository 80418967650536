import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { GeneralAvailability } from '@core/models';
import {
  AvailabilityService,
  DvmService,
  TutorialService,
} from '@core/services';
import { Subscription } from 'rxjs';
import { BestAvailableService } from '../../services/best-available.service';
import { Popover3dviewService } from '../../services/popover-3dview.service';

@Component({
  selector: 'app-section-popover',
  templateUrl: './section-popover.component.html',
  styleUrls: ['./section-popover.component.scss'],
})
export class SectionPopoverComponent implements OnInit, OnDestroy {
  private availabilityService = inject(AvailabilityService);
  private bestAvailableService = inject(BestAvailableService);
  private dvmService = inject(DvmService);
  private popover3dview = inject(Popover3dviewService);
  private tutorialService = inject(TutorialService);
  includedSectionForBA: boolean;
  sectionData: GeneralAvailability;
  subscriptions: Subscription[] = [];
  tooltipData: { section: string; img: string; type: string } = {
    section: '',
    img: '',
    type: '',
  };

  get priceRange() {
    let priceRange = this.sectionData.priceRange[0].value.toString(10);
    if (!this.sectionData) {
      return '';
    }
    if (
      this.sectionData.priceRange[0].value !=
      this.sectionData.priceRange[1].value
    ) {
      priceRange = `${this.sectionData.priceRange[0].value} - ${this.sectionData.priceRange[1].value}`;
    }
    return priceRange;
  }

  get adaQuantity() {
    // TODO: change this access to mmcID
    if (
      this.availabilityService.sectionsAvailability[
        this.sectionData.sectionID
      ] &&
      this.availabilityService.sectionsAvailability[this.sectionData.sectionID]
        .availableQuantityAda
    ) {
      return this.availabilityService.sectionsAvailability[
        this.sectionData.sectionID
      ].availableQuantityAda;
    } else if (
      this.availabilityService.sectionsOnlyAdas[this.sectionData.sectionID] &&
      this.availabilityService.sectionsOnlyAdas[this.sectionData.sectionID]
        .availableQuantityAda
    ) {
      return this.availabilityService.sectionsOnlyAdas[
        this.sectionData.sectionID
      ].availableQuantityAda;
    }
    return 0;
  }

  ngOnInit(): void {
    this.initializeComponent();
  }

  initializeComponent() {
    // Device detection
    this.includedSectionForBA =
      this.bestAvailableService.selectedSectionsForBA.includes(
        this.sectionData.sectionID
      );

    this.tooltipData.section = this.sectionData.mmcID as string;

    // THUMBNAIL
    let thumbSection = this.sectionData.sectionID;

    if (this.tooltipData.section === 'S_BLCHADA') {
      thumbSection = 'S_Bleacher';
    }

    const subscription = this.dvmService.loadThumbnail(thumbSection).subscribe({
      next: (img: HTMLImageElement) => (this.tooltipData.img = img.src),
      error: error => console.error(error),
    });
    this.subscriptions.push(subscription);
  }

  load3dView() {
    if (this.tutorialService.isOpen()) {
      return;
    }

    this.popover3dview.removeTooltip();
    this.popover3dview.deleteTimer();
    this.dvmService.load3dView(this.sectionData.sectionID);
  }

  onEnter(event: MouseEvent) {
    this.popover3dview.deleteTimer();
  }

  onLeave(event: MouseEvent) {
    this.popover3dview.setTimer();
  }

  openSeatMap() {
    if (this.tutorialService.isOpen()) {
      return;
    }

    const sectionId: string = this.sectionData.sectionID;

    const subscription = this.dvmService.openSeatMap(sectionId).subscribe();
    this.subscriptions.push(subscription);
    this.popover3dview.setTimer();
  }

  selectSectionForBA() {
    if (this.tutorialService.isOpen()) {
      return;
    }

    if (!this.includedSectionForBA) {
      this.bestAvailableService.selectSectionForBA(this.sectionData.sectionID);
    } else {
      this.bestAvailableService.unselectSectionForBA(
        this.sectionData.sectionID
      );
    }
    this.includedSectionForBA =
      this.bestAvailableService.selectedSectionsForBA.includes(
        this.sectionData.sectionID
      );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
