import { inject, Injectable } from '@angular/core';
import { Seat } from '@core/models';
import { DvmService } from './dvm.service';
import { MapViewerOutputNode } from '@3ddv/dvm-internal';

@Injectable({
  providedIn: 'root',
})

/* ISOLATED SEAT HANDLING */
export class IsolatedSeatsService {
  private dvmService = inject(DvmService);
  isolatedSeatsActive = false;
  lastIsolatedSeats: Seat[] | null;

  public checkIsolatedSeats(seats: MapViewerOutputNode[]) {
    const isolatedSeats = [];
    for (let i = 0; i < seats.length; ++i) {
      let seat = seats[i];
      if (typeof seat === 'string') {
        seat = this.dvmService.viewer.getNodeById(seat);
      }
      if (seat && seat.row) {
        const row = seat.row;
        const index = row.indexOf(seat.id);
        const right_id1 = row[index + 1];
        const right_id2 = row[index + 2];
        const left_id1 = row[index - 1];
        const left_id2 = row[index - 2];

        if (this.isAvailable(right_id1 as string)) {
          // Si hay uno disponible a la derecha
          if (!this.isAvailable(right_id2 as string)) {
            // Si no hay uno disponible 2 a la derecha
            isolatedSeats.push(right_id1); // El de la derecha está aislado
          }
        }
        if (this.isAvailable(left_id1 as string)) {
          // Si hay uno disponible a la izquierda
          if (!this.isAvailable(left_id2 as string)) {
            // Si no hay uno disponible 2 a la izquierda
            isolatedSeats.push(left_id1); // El de la izquierda está aislado
          }
        }
      } else {
        console.error('Seat or Row not matching request: ', seat);
      }
    }
    return isolatedSeats;
  }

  private isAvailable(id: string) {
    if (id) {
      const element = this.dvmService.viewer.getNodeById(id);
      if (!element) {
        console.error('Element not found: ', id);
        return false;
      }
      return element.state === 'available';
    }
    return false; // Si no existe, no es available
  }
}
