import {
  MapViewerLoadMapOptions,
  MapViewerLoadObject,
  MapViewerNode,
  MapViewerPluginsList,
  MapViewerStyles,
  MapViewerStylesState,
  Viewer3dLoadView3dOptions,
} from '@3ddv/dvm-internal';
import {
  MapViewerInitOptions,
  MapViewerService,
  Viewer3dInitOptions,
  Viewer3dService,
} from '@3ddv/ngx-dvm-internal';
import { ApiCoreFlags } from '@3ddv/ngx-dvm-internal/lib/map-viewer/apis/core';
import {
  computed,
  inject,
  Injectable,
  OnDestroy,
  Signal,
  signal,
  ViewContainerRef,
  WritableSignal,
} from '@angular/core';
import { createPopper, Placement } from '@popperjs/core';
import { Observable, Subscription, tap, timer } from 'rxjs';
import { DvmData, DvmStyles } from '../models/configuration.model';
import { ConfigurationService } from './configuration.service';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root',
})
export class DvmService implements OnDestroy {
  /**
   * DVM SERVICES
   */
  public readonly viewerService = inject(MapViewerService);
  public readonly configurationService = inject(ConfigurationService);
  public minimapService: MapViewerService;
  public viewer3dService: Viewer3dService;

  /**
   * INIT OPTIONS
   */
  private mapViewerInitOptions: MapViewerInitOptions = { plugins: [] };
  private viewer3dInitOptions: Viewer3dInitOptions = {};

  /**
   * LOAD OPTIONS
   */
  public mapViewerLoadOptions: MapViewerLoadMapOptions = {
    venue_id: '',
    map_id: '',
  };
  public minimapLoadOptions: MapViewerLoadMapOptions = {
    venue_id: '',
    map_id: '',
  };
  private viewer3dLoadOptions: Viewer3dLoadView3dOptions = {
    venue_id: '',
    view_id: '',
  };

  /**
   * STYLES
   */
  private customStyles!: DvmStyles;

  /**
   * MISC
   */
  private readonly baseAvailability: string[] = [];
  isFirstSeatMapLoad = true;
  isMultiSelectTooltipVisible: boolean = false;
  popoverPlacement: ViewContainerRef;
  viewerSubscriptions: Subscription[] = [];
  private sectionsMmcIdToTdc: Record<string, any> = {};
  private sectionsTdcToMmc: Record<string, any> = {};

  /**
   * UTILITIES
   */
  private _isTopView: WritableSignal<boolean> = signal(true);
  private _is3dView: WritableSignal<boolean> = signal(false);
  private _showMinimap: WritableSignal<boolean> = signal(false);
  public readonly isTopView: Signal<boolean> = computed(() =>
    this._isTopView()
  );
  public readonly is3dView: Signal<boolean> = computed(() => this._is3dView());
  public readonly showMinimap: Signal<boolean> = computed(() =>
    this._showMinimap()
      ? !this.is3dView() && !this.isTopView()
      : this._showMinimap()
  );

  /**
   * Creates a popover for a given node and tooltip element.
   *
   * @param node - The MapViewerNode for which the popover is created.
   * @param tooltip - The HTML element that serves as the tooltip.
   * @param elementDOMId - The DOM ID of the element used for positioning reference.
   *
   * @remarks
   * This method calculates the center position of the node in the DOM, determines the appropriate placement
   * (top or bottom) based on the node's vertical center, and creates a popper with specified offset and flip options.
   * The tooltip is then displayed by setting the `data-show` attribute.
   *
   * @author Jonathan Alvarado
   */
  public createPopover(
    node: MapViewerNode,
    tooltip: HTMLElement,
    options: Parameters<typeof createPopper>[2]
  ) {
    const popper = createPopper(node, tooltip, options);

    tooltip.setAttribute('data-show', '');

    return popper;
  }

  /**
   * Determines the placement of a node relative to a DOM element.
   *
   * @param node - The MapViewerNode object containing the node's bounding box (aabb).
   * @param elementDOMId - The ID of the DOM element to compare against.
   * @returns The placement of the node ('top' or 'bottom') relative to the vertical center of the DOM element.
   *
   * @author Jonathan Alvarado
   */
  public getPlacement(node: MapViewerNode, elementDOMId: string): Placement {
    const nodeDomCenter = this.viewerService.fromSceneToDom([
      node.aabb![0],
      node.aabb![1],
    ]);
    const nodeHalfHeight = node.aabb![3] / 2;
    const nodeVerticalCenter = nodeDomCenter![1] + nodeHalfHeight;
    const element = document.getElementById(elementDOMId) as HTMLElement;
    return nodeVerticalCenter > element.offsetHeight / 2 ? 'top' : 'bottom';
  }

  /**
   * Calculates the offset for a given MapViewerNode based on its type.
   *
   * @param node - The MapViewerNode for which to calculate the offset.
   * @returns A tuple containing the x and y offsets.
   *
   * @author Jonathan Alvarado
   */
  public getOffset(node: MapViewerNode): [number, number] {
    return node.type === 'seat' ? [0, 6] : [0, -10];
  }

  /**
   * UTILITIES
   */
  private formatRgb: (rgbString: string) => string =
    inject(UtilitiesService).formatRgb;

  /**
   *
   * @param {DvmData} data
   * @param {MapViewerPluginsList[]} plugins
   * @param {ApiCoreFlags} flags
   * @param {DvmStyles} customStyles
   * @param {string} customClient
   * @param {string[]} baseAvailability
   * @param {boolean} debugMode
   */
  public initMapViewer(
    data: DvmData,
    plugins?: MapViewerPluginsList[] | null,
    flags?: ApiCoreFlags | null,
    customStyles?: DvmStyles | null,
    customClient?: string | null,
    baseAvailability?: string[] | null,
    debugMode?: boolean
  ): void {
    // Si no esta inicializado, lo inicializamos
    if (!this.viewerService.isInitialized()) {
      // Asignamos los plugins
      plugins ? this.setPlugins(plugins) : false;

      // Asignamos los estilos
      customStyles ? (this.customStyles = customStyles) : false;

      // Asignamos el cliente
      customClient ? this.setClient(customClient) : this.setClient();

      // Inicializamos el viewer
      const subscription = this.viewerService
        .initialize(this.mapViewerInitOptions)
        .subscribe(() => {
          // Asignamos las opciones de carga
          this.setViewerLoadOptions(data);

          if (baseAvailability) {
            this.setBaseAvailability(baseAvailability);
          }

          // Cargamos el mapa
          const subscription = this.loadMap(
            this.mapViewerLoadOptions
          ).subscribe(() => {
            // Seteamos los flags
            if (flags) {
              this.setFlags(flags);
            }

            if (baseAvailability) {
              this.setMapAvailability(undefined, baseAvailability);
            }

            // Modo debug
            if (debugMode) {
              (window as any)['viewer'] = this.viewerService;
            }
          });

          this.viewerSubscriptions.push(subscription);
        });

      this.viewerSubscriptions.push(subscription);
    } else {
      // Asignamos las opciones de carga
      this.setViewerLoadOptions(data);

      // Asignamos la disponibilidad base
      if (baseAvailability) {
        this.setBaseAvailability(baseAvailability);
      }

      // Cargamos el mapa
      const subscription = this.loadMap(this.mapViewerLoadOptions).subscribe(
        () => {
          // Seteamos los flags
          if (flags) {
            this.setFlags(flags);
          }

          // Seteamos la disponibilidad
          if (baseAvailability) {
            this.setMapAvailability(undefined, this.baseAvailability);
          }

          // Modo debug
          if (debugMode) {
            (window as any)['viewer'] = this.viewerService;
          }
        }
      );

      this.viewerSubscriptions.push(subscription);
    }
  }

  public initViewer3d(service: Viewer3dService, venue_id?: string): void {
    if (!this.viewer3dService || !this.viewer3dService.isInitialized()) {
      this.viewer3dService = service;
      const subscription = this.viewer3dService
        ?.initialize(this.viewer3dInitOptions)
        .subscribe(() => {
          this.setViewer3dLoadOptions(venue_id);
        });
      this.viewerSubscriptions.push(subscription);
    }
  }

  public initMinimap(
    minimapService: MapViewerService,
    dvmData: DvmData,
    debugMode?: boolean
  ): void {
    if (!dvmData.miniMapId && !dvmData.miniMapEnabled) {
      console.log('No se ha configurado el minimapa');
      return;
    }

    this.minimapService = minimapService;
    this._showMinimap.update(x => !x);

    if (!this.minimapService.isInitialized()) {
      const options = Object.assign({}, this.mapViewerInitOptions);
      options.client_id = 'tdc';
      options.version = 'latest';

      const subscription = this.minimapService.initialize(options).subscribe({
        next: () => {
          if (debugMode) {
            (window as any)['minimap'] = this.minimapService;
          }

          this.setFlagsMini();
          this.setMinimapLoadOptions(dvmData);
          this.loadMinimap();
        },
        error: e => console.log(e),
      });
      this.viewerSubscriptions.push(subscription);
    }

    return;
  }

  public openSectionMap(): Observable<MapViewerLoadObject> {
    return this.loadMap();
  }

  public openSeatMap(sectionId: string): Observable<MapViewerLoadObject> {
    // Cargamos objeto de config del mapa y le reasignamos la propiedad map id
    const loadOptions: MapViewerLoadMapOptions = this.getMapLoadOptions();
    loadOptions.map_id = sectionId;

    return this.loadMap(loadOptions);
  }

  public setMapAvailability(type?: string, availability?: string[]): void {
    if (type && availability) {
      this.viewerService.setAvailability(type, availability);
    } else {
      this.viewerService
        .getTypesList()
        .forEach(t =>
          this.viewerService.setAvailability(
            type ?? t,
            availability ?? this.viewerService.getNodesByType(type ?? t)
          )
        );
    }
  }

  public getMapLoadOptions(): MapViewerLoadMapOptions {
    return Object.assign({}, this.mapViewerLoadOptions);
  }

  public getViewer3dLoadOptions(): Viewer3dLoadView3dOptions {
    return Object.assign({}, this.viewer3dLoadOptions);
  }

  public loadThumbnail(viewId: string): Observable<HTMLImageElement> {
    const loadOptions: Viewer3dLoadView3dOptions = {
      venue_id: this.mapViewerLoadOptions.venue_id,
      view_id: viewId,
    };

    return this.viewerService.getThumbnail(loadOptions);
  }

  public load3dView(viewId: string): void {
    if (this.viewer3dService.isLoaded()) {
      const viewLoaded = this.viewer3dService.getViewId();

      if (viewLoaded !== viewId) {
        this.viewer3dService.reset();
      }
    }

    const load3dOptions: Viewer3dLoadView3dOptions =
      this.getViewer3dLoadOptions();

    load3dOptions.view_id = viewId;

    const subscription = this.viewer3dService
      .loadView3d(load3dOptions)
      .subscribe({
        next: () => {
          // Seteamos Flags
          this.setFlags3d();

          // Si is3dView es false,es que el viewer estaba cerrado, updateamos a false,
          // pero si ya estaba en true y estamos cargando de nuevo, seguimos mostrando el viewer
          !this.is3dView() ? this._is3dView.update(x => !x) : null;

          // Agregamos label position
          this.viewer3dService.interface.setLabelPosition('bottomright');
        },
        error: e => console.log(e),
      });

    this.viewerSubscriptions.push(subscription);
  }

  public close3dView(): void {
    if (this.is3dView()) {
      this._is3dView.set(false);
    }
    return;
  }

  public translateSectionMmcId(mmcId: string): string | null {
    return this.sectionsMmcIdToTdc[mmcId] || null;
  }

  public translateSectionTdcId(tdcId: string): string | null {
    return this.sectionsTdcToMmc[tdcId] || null;
  }

  /**
   * INIT METHODS
   */
  private setViewerLoadOptions(data: DvmData): void {
    this.mapViewerLoadOptions.venue_id = data.venueId;
    this.mapViewerLoadOptions.map_id = data.mapId;
  }

  private setMinimapLoadOptions(data: DvmData): void {
    this.minimapLoadOptions.venue_id = data.venueId;
    this.minimapLoadOptions.map_id = data.miniMapId;
  }

  private setViewer3dLoadOptions(venue_id?: string): void {
    this.viewer3dLoadOptions.venue_id =
      venue_id ?? this.viewerService.getVenueId()!;
  }

  public loadMap(
    loadOptions?: MapViewerLoadMapOptions
  ): Observable<MapViewerLoadObject> {
    const mapId = loadOptions
      ? (loadOptions.map_id as string)
      : (this.mapViewerLoadOptions.map_id as string);

    const isTopView = this.checkIsTopView(mapId as string);
    this.zoomInMinimap(isTopView, mapId as string);
    this.showMultiSelecTooltip(isTopView);

    if (this.showMinimap()) {
      this._showMinimap.update(x => !x);
    }

    return this.viewerService
      .loadMap(loadOptions ?? this.mapViewerLoadOptions)
      .pipe(
        tap(() => this.setFlags()),
        tap(() => this.applyStyles()),
        tap(() => this.updateSectionIdTranslation()),
        tap(() => this._showMinimap.update(x => !x))
      );
  }

  private zoomInMinimap(isTopView: boolean, sectionMapId: string): void {
    if (isTopView) {
      if (
        this.minimapService.isLoaded() &&
        this.minimapService.isInitialized()
      ) {
        this.minimapService.goTo([0, 0], 1, 100);
      }
    } else {
      const tdcId = this.translateSectionTdcId(sectionMapId);
      const nodeId = tdcId ?? sectionMapId;
      const selectedSectionNodes = this.minimapService.getNodesByState(
        'section',
        'selected'
      );
      this.minimapService.setState(selectedSectionNodes, 'unavailable', true);

      const timeoutId = setTimeout(() => {
        this.minimapService.setState(nodeId, 'selected', true);
        this.minimapService.goTo(tdcId ?? sectionMapId, 3, 1500);
        clearTimeout(timeoutId);
      }, 3000);
    }
  }

  private showMultiSelecTooltip(isTopView: boolean): void {
    if (isTopView) {
      this.isMultiSelectTooltipVisible = false;
    } else {
      this.isMultiSelectTooltipVisible = !!this.isFirstSeatMapLoad;
      const isFirstSeatMapLoad = this.isFirstSeatMapLoad;
      const multiSelectionToastSubscription = timer(10000).subscribe(() => {
        if (isFirstSeatMapLoad) {
          this.isMultiSelectTooltipVisible = false;
        }
        multiSelectionToastSubscription.unsubscribe();
      });
      this.viewerSubscriptions.push(multiSelectionToastSubscription);
    }
  }

  private loadMinimap() {
    const subscription = this.minimapService
      .loadMap(this.minimapLoadOptions)
      .subscribe({
        next: () => {
          this.setFlagsMini();
          this._showMinimap.update(x => !x);
        },
        error: e => console.log(e),
      });

    this.viewerSubscriptions.push(subscription);
  }

  private setClient(client: string = 'tdc'): void {
    this.mapViewerInitOptions['client_id'] = client;
  }

  private setPlugins(plugins: MapViewerPluginsList[]): void {
    this.mapViewerInitOptions['plugins'] = plugins;
  }

  private setFlags(flags?: ApiCoreFlags): void {
    // DEFAULT FLAGS
    if (!flags) {
      if (this.configurationService.configuration.dvmData.limitedZoom) {
        this.viewerService.flags.max_zoom_on_first_limit = true;
      }

      this.viewerService.flags.preserve_min_scaling_factor = true;
      this.viewerService.flags.fixed_aspect_ratio = false;
      this.viewerService.flags.automatic_selection = false;
    }
    // CUSTOM FLAGS
    else {
      Object.entries(flags).forEach(([key, value]) => {
        if (this.viewerService.flags.hasOwnProperty(key)) {
          this.viewerService.flags[key as keyof ApiCoreFlags] = value;
        }
      });
    }
  }

  private setFlagsMini(flags?: ApiCoreFlags): void {
    // DEFAULT FLAGS
    if (!flags) {
      this.minimapService.flags.fixed_aspect_ratio = false;
      this.minimapService.flags.automatic_selection = false;
      this.minimapService.flags.automatic_hover = false;
      this.minimapService.flags.zooming = false;
      this.minimapService.flags.panning = false;
    }
    // CUSTOM FLAGS
    else {
      Object.entries(flags).forEach(([key, value]) => {
        if (this.minimapService.flags.hasOwnProperty(key)) {
          this.minimapService.flags[key as keyof ApiCoreFlags] = value as any;
        }
      });
    }
  }

  private setFlags3d(): void {
    this.viewer3dService.flags.fixed_aspect_ratio = false;
  }

  private applyStyles(): void {
    if (!this.customStyles) {
      return;
    }

    // DVM STYLES
    const styles: MapViewerStyles = this.viewerService.getStyles();

    // SECTION STYLES
    if (styles && styles[0]['section']) {
      const sectionAvailable: MapViewerStylesState = styles[0]['section'][
          'available'
        ]! as MapViewerStylesState,
        sectionSelected: MapViewerStylesState = styles[0]['section'][
          'selected'
        ]! as MapViewerStylesState;

      // AVAILABLE NORMAL
      sectionAvailable.normal.none.fillStyle = this.formatRgb(
        this.customStyles['section-available-normal-fill']
      );
      sectionAvailable.normal.none.strokeStyle = this.formatRgb(
        this.customStyles['section-available-normal-stroke']
      );

      // AVAILABLE HOVER
      sectionAvailable.hover!.none.fillStyle = this.formatRgb(
        this.customStyles['section-available-hover-fill']
      );
      sectionAvailable.hover!.none.strokeStyle = this.formatRgb(
        this.customStyles['section-available-hover-stroke']
      );

      // SELECTED NORMAL
      sectionSelected.normal.none.fillStyle = this.formatRgb(
        this.customStyles['section-selected-normal-fill']
      );
      sectionSelected.normal.none.strokeStyle = this.formatRgb(
        this.customStyles['section-selected-normal-stroke']
      );

      // SELECTED HOVER
      sectionSelected.hover!.none.strokeStyle = this.formatRgb(
        this.customStyles['section-selected-hover-stroke']
      );

      styles[0]['section']['available'] = sectionAvailable;
      styles[0]['section']['selected'] = sectionSelected;
    }

    // SEAT STYLES
    if (styles && styles[0]['seat']) {
      const seatAvailable: MapViewerStylesState = styles[0]['seat'][
          'available'
        ]! as MapViewerStylesState,
        seatUnavailable: MapViewerStylesState = styles[0]['seat'][
          'unavailable'
        ]! as MapViewerStylesState;

      // AVAILABLE NORMAL
      seatAvailable.normal.none.fillStyle = this.formatRgb(
        this.customStyles['seat-available-none-fill']
      );
      seatAvailable.normal.none.strokeStyle = this.formatRgb(
        this.customStyles['seat-available-none-stroke']
      );

      // DISABLED NORMAL
      seatAvailable.normal['disabled'].fillStyle = this.formatRgb(
        this.customStyles['seat-available-disabled-fill']
      );
      seatAvailable.normal['disabled'].strokeStyle = this.formatRgb(
        this.customStyles['seat-available-disabled-stroke']
      );

      // PENDING NORMAL
      seatAvailable.normal['pending'].fillStyle = this.formatRgb(
        this.customStyles['seat-available-pending']
      );
      seatAvailable.normal['pending'].strokeStyle = this.formatRgb(
        this.customStyles['seat-available-pending']
      );

      // UNAVAILABLE NORMAL
      seatUnavailable.normal['none'] = {
        fillStyle: this.formatRgb(this.customStyles['seat-unavailable-none']),
        strokeStyle: this.formatRgb(this.customStyles['seat-unavailable-none']),
        lineWidth: 0.05,
        opacity: 0.5,
      };

      styles[0]['seat']['available'] = seatAvailable;
      styles[0]['seat']['unavailable'] = seatUnavailable;
    }

    this.viewerService.setStyles(styles);
  }

  private setBaseAvailability(availability: string[]): void {
    this.baseAvailability.push(...availability);
  }

  private checkIsTopView(mapId: string): boolean {
    const isTopView = mapId === this.mapViewerLoadOptions.map_id;
    this._isTopView.set(isTopView);
    return isTopView;
  }

  private updateSectionIdTranslation() {
    if (this._isTopView()) {
      const sections = this.viewerService.getNodesByType('section');
      const gaSections = this.viewerService.getNodesByType('general_admission');
      const mergedSections = [...sections, ...gaSections];

      for (const section of mergedSections) {
        if (!this.sectionsMmcIdToTdc[section.original_id]) {
          this.sectionsMmcIdToTdc[section.original_id] = section.id;
        }
        if (!this.sectionsTdcToMmc[section.id]) {
          this.sectionsTdcToMmc[section.id] = section.original_id;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.viewerSubscriptions.forEach(sub => sub.unsubscribe());
  }
}
