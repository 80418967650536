import { Component, inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DvmService } from '@features/digital-venue/services/dvm.service';

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss'],
})
export class ModalsComponent {
  title: string;
  content: string;
  closeBtnName: string;
  acceptBtnName: string;
  closeFunction: () => void;
  acceptFunction: () => void;

  // Legend
  availableColor: string = 'main-light';
  mySeatsColor: string = 'red';
  selectedColor: string = 'purple';
  isolatedColor: string = 'orange';

  bsModalRef = inject(BsModalRef);
  private dvmService = inject(DvmService);

  get isTopview() {
    return this.dvmService.isTopview;
  }

  accept() {
    this.bsModalRef.hide();
    if (this.acceptFunction) {
      this.acceptFunction();
    }
  }

  close() {
    this.bsModalRef.hide();
    if (this.closeFunction) {
      this.closeFunction();
    }
  }
}
