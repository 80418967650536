import { Component, inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AvailabilityService } from '@core/services';
import { BestAvailableService } from '@features/digital-venue/services/best-available.service';
import { SeatManagementService } from '@features/digital-venue/services/seat-management.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModalsService } from '../modals.service';

@Component({
  selector: 'app-ga-modal',
  templateUrl: './ga-modal.component.html',
  styleUrls: ['./ga-modal.component.scss'],
})
export class GaModalComponent implements OnInit, OnDestroy {
  title: string;
  subtitle?: string;
  content: string;
  closeBtnName: string;
  closeFunction: () => void;
  acceptBtnName: string;
  acceptFunction: () => void;
  isAda: boolean;

  gaNumSeats: number = 0;
  seatsAvailable: string[] = [];
  subscriptions: Subscription[] = [];

  bsModalRef = inject(BsModalRef);
  private availabilityService = inject(AvailabilityService);
  private seatManagementService = inject(SeatManagementService);
  private modalsService = inject(ModalsService);
  private bestAvailableService = inject(BestAvailableService);
  private ngZone = inject(NgZone);

  ngOnInit(): void {
    this.initComponent();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private initComponent(): void {
    const section = this.getGASection();

    const subscription = this.availabilityService
      .getSeatAvailability([section])
      .subscribe(data => {
        let adaAvailability = Object.values(
          this.availabilityService.seatsAvailability[section]
        )
          .filter(a => a.isAda)
          .map(a => a.id);
        if (this.isAda) {
          this.seatsAvailable = adaAvailability;
        } else {
          this.seatsAvailable = data.filter(a => !adaAvailability.includes(a));
        }
      });

    this.subscriptions.push(subscription);
  }

  private getGASection() {
    return this.bestAvailableService.lastSelectedSection;
  }

  // on accept we randomly select some seats and add to selectSeats for cart
  accept() {
    this.bsModalRef.hide();

    if (this.seatsAvailable.length >= this.gaNumSeats) {
      this.ngZone.run(() => {
        this.seatManagementService.selectSeatsForCart(
          this.seatsAvailable.slice(0, this.gaNumSeats)
        );
      });
    } else {
      const modalData = {
        title: 'ERROR',
        content: 'Not enough seats are available in this section.',
        acceptBtnName: 'CLOSE',
      };
      this.modalsService.openModal(modalData);
    }
  }

  close() {
    this.bsModalRef.hide();
    if (this.closeFunction) {
      this.closeFunction();
    }
  }
}
