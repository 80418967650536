import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  ApiResponse,
  GeneralAvailabilityDict,
  PrivateSellingWhitelistItem,
  SaleEvent,
  SeatAvailabilityDict,
  TransactionModel,
} from '@core/models';
import { BaseConnectionService } from '@core/services/base-connection.service';
import { Client } from '@shared/models/client.model';
import { map, Observable, Subject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService extends BaseConnectionService {
  private http = inject(HttpClient);
  eventSubject: Subject<SaleEvent> = new Subject<SaleEvent>();

  public token: string;
  public eventId: number;

  // EVENTS
  // Get Events by ID
  // Recupera todo los datos del evento juntos sus upgrades y upsell
  getEventById(eventId: number) {
    const endpoint = `${this.apiURL}/events/${eventId}`;
    return this.sendRequest<SaleEvent>(this.http, endpoint).pipe(
      tap(data => this.eventSubject.next(data.data as SaleEvent))
    );
  }

  getEvent() {
    return this.eventSubject.asObservable();
  }

  // Recupera todas las secciones disponibles desde PV
  getGeneralAvailability(eventId: number) {
    const endpoint = `${this.apiURL}/events/${eventId}/availability`;
    return this.sendRequest<GeneralAvailabilityDict>(this.http, endpoint);
  }

  getMultiSectionAvailability(
    eventId: number,
    sectionsArray: string[]
  ): Observable<ApiResponse<SeatAvailabilityDict>> {
    if (!sectionsArray.length || typeof sectionsArray[0] != 'string') {
      return new Observable(r => r.error('Sections array is empty.'));
    }

    const queryParams = new URLSearchParams(
      sectionsArray.map(value => ['sectionId', value])
    );

    const endpoint = `${this.apiURL}/events/${eventId}/availability/multi-section?${queryParams.toString()}`;

    return this.sendRequest<SeatAvailabilityDict>(this.http, endpoint);
  }

  // TRANSACTIONS

  // Create transaction
  // Creamos la transaccion, hacemos lock de los seats en Provenue
  // La response devolvera, id, status y **token**
  // El token es unico por transaccion y se debe pasar para poder realizar peticiones.
  createTransaction(
    pvEventId: number,
    seatsArray: string[]
  ): Observable<ApiResponse<TransactionModel>> {
    if (!seatsArray.length) {
      return new Observable(r => r.error('Seats array is empty.'));
    }

    const endpoint = `${this.apiURL}/transaction`;
    const body = { seats: seatsArray, pvEventId };
    return this.sendRequest<TransactionModel>(this.http, endpoint, body);
  }

  getPatronIdFromWhitelist(email: string) {
    const endpoint = `${this.apiURL}/private-selling/${email}`;
    return this.sendRequest<PrivateSellingWhitelistItem>(
      this.http,
      endpoint
    ).pipe(map(obj => obj.data));
  }

  getCurrentClient() {
    const endpoint = `${this.apiURL}/clients/current`;
    return this.sendRequest<Client>(this.http, endpoint);
  }
}
